// From angular-material.css
/* Sizes as by bootstrap
sm-min <- size < sm-max (sm: tablet)
md-min <- size < md-max (md: tablet landscape / desktop)
lg-min <- size  (lg: desktop)
*/
.flex {
    box-sizing: border-box;
    flex: 1;
}

.flex-0 {
    flex: 0 0 0%;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-2 {
    flex: 2 1 0%;
}


.flex-5 {
    flex: 0 0 5%;
}

.flex-10 {
    flex: 0 0 10%;
}

.flex-15 {
    flex: 0 0 15%;
}

.flex-20 {
    flex: 0 0 20%;
}

.flex-25 {
    flex: 0 0 25%;
}

.flex-30 {
    flex: 0 0 30%;
}

.flex-35 {
    flex: 0 0 35%;
}

.flex-40 {
    flex: 0 0 40%;
}

.flex-45 {
    flex: 0 0 45%;
}

.flex-50 {
    flex: 0 0 50%;
}

.flex-55 {
    flex: 0 0 55%;
}

.flex-60 {
    flex: 0 0 60%;
}

.flex-65 {
    flex: 0 0 65%;
}

.flex-70 {
    flex: 0 0 70%;
}

.flex-75 {
    flex: 0 0 75%;
}

.flex-80 {
    flex: 0 0 80%;
}

.flex-85 {
    flex: 0 0 85%;
}

.flex-90 {
    flex: 0 0 90%;
}

.flex-95 {
    flex: 0 0 95%;
}

.flex-100 {
    flex: 0 0 100%;
}

.flex-33,
.flex-34 {
    flex: 0 0 33.33%;
}

.flex-66,
.flex-67 {
    flex: 0 0 66.66%;
}

.layout-column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    & > .flex-0 {
        max-height: 0%;
    }

    & > .flex-5 {
        max-height: 5%;
    }

    & > .flex-10 {
        max-height: 10%;
    }

    & > .flex-15 {
        max-height: 15%;
    }

    & > .flex-20 {
        max-height: 20%;
    }

    & > .flex-25 {
        max-height: 25%;
    }

    & > .flex-30 {
        max-height: 30%;
    }

    & > .flex-35 {
        max-height: 35%;
    }

    & > .flex-40 {
        max-height: 40%;
    }

    & > .flex-45 {
        max-height: 45%;
    }

    & > .flex-50 {
        max-height: 50%;
    }

    & > .flex-55 {
        max-height: 55%;
    }

    & > .flex-60 {
        max-height: 60%;
    }

    & > .flex-65 {
        max-height: 65%;
    }

    & > .flex-70 {
        max-height: 70%;
    }

    & > .flex-75 {
        max-height: 75%;
    }

    & > .flex-80 {
        max-height: 80%;
    }

    & > .flex-95 {
        max-height: 95%;
    }

    & > .flex-100 {
        max-height: 100%;
    }

    & > .flex-33,
    & > .flex-34 {
        max-height: 33.33%;
    }

    & > .flex-66,
    & > .flex-67 {
        max-height: 66.66%;
    }
}

.layout-row {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    & > .flex-0 {
        max-width: 0%;
    }

    & > .flex-5 {
        max-width: 5%;
    }

    & > .flex-10 {
        max-width: 10%;
    }

    & > .flex-15 {
        max-width: 15%;
    }

    & > .flex-20 {
        max-width: 20%;
    }

    &  > .flex-25 {
        max-width: 25%;
    }

    & > .flex-30 {
        max-width: 30%;
    }

    & > .flex-35 {
        max-width: 35%;
    }

    & > .flex-40 {
        max-width: 40%;
    }

    & > .flex-45 {
        max-width: 45%;
    }

    &  > .flex-50 {
        max-width: 50%;
    }

    & > .flex-55 {
        max-width: 55%;
    }

    & > .flex-60 {
        max-width: 60%;
    }

    & > .flex-65 {
        max-width: 65%;
    }

    & > .flex-70 {
        max-width: 70%;
    }

    &  > .flex-75 {
        max-width: 75%;
    }

    &  > .flex-80 {
        max-width: 80%;
    }

    & > .flex-85 {
        max-width: 85%;
    }

    & > .flex-90 {
        max-width: 90%;
    }

    & > .flex-95 {
        max-width: 95%;
    }

    & > .flex-100 {
        max-width: 100%;
    }

    & > .flex-33,
    & > .flex-34 {
        max-width: 33.33%;
    }

    & > .flex-66,
    & > .flex-67 {
        max-width: 66.66%;
    }
}

.layout-padding,
.layout-padding > .flex {
    padding: 8px;
}

.layout-margin,
.layout-margin > .flex {
    border: 8px solid transparent;
    background-clip: padding-box;
}

.layout-wrap {
    flex-wrap: wrap;
}

.layout-nowrap {
    flex-wrap: nowrap;
}

.layout-fill {
    margin: 0;
    min-height: 100%;
    width: 100%;
}

.layout-align-center,
.layout-align-center-center,
.layout-align-center-start,
.layout-align-center-end,
.layout-align-center-stretch {
    justify-content: center;
}

.layout-align-end,
.layout-align-end-center,
.layout-align-end-start,
.layout-align-end-end,
.layout-align-end-stretch {
    justify-content: flex-end;
}

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
    justify-content: space-around;
}

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
    justify-content: space-between;
}

.layout-align-center-center,
.layout-align-start-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
    align-items: center;
}

.layout-align-center-start,
.layout-align-start-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
    align-items: flex-start;
}

.layout-align-center-end,
.layout-align-start-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
    align-items: flex-end;
}

.layout-align-center-stretch,
.layout-align-start-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
    align-items: stretch;
}

.align-self-flex-start {
    align-self: flex-start;
}

.align-self-flex-end {
    align-self: flex-end;
}

.align-self-center {
    align-self: center;
}

.align-self-baseline {
    align-self: baseline;
}

.align-self-stretch {
    align-self: stretch;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-grow-2 {
    flex-grow: 2;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

@media (max-width: $screen-xs-max) {
    .flex-xs-90 {
        flex: 0 0 90%;
    }

    .layout-row > .flex-xs-90 {
        max-width: 90%;
    }

    .layout-column > .flex-xs-90 {
        max-height: 90%;
    }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .hide:not(.show-gt-xs):not(.show-sm):not(.show) {
        display: none;
    }

    .hide-sm:not(.show-sm):not(.show) {
        display: none;
    }

    .flex-sm {
        box-sizing: border-box;
        flex: 1;
    }

    .flex-sm-0 {
        flex: 0 0 0%;
    }

    .flex-sm-5 {
        flex: 0 0 5%;
    }

    .flex-sm-10 {
        flex: 0 0 10%;
    }

    .flex-sm-15 {
        flex: 0 0 15%;
    }

    .flex-sm-20 {
        flex: 0 0 20%;
    }

    .flex-sm-25 {
        flex: 0 0 25%;
    }

    .flex-sm-30 {
        flex: 0 0 30%;
    }

    .flex-sm-35 {
        flex: 0 0 35%;
    }

    .flex-sm-40 {
        flex: 0 0 40%;
    }

    .flex-sm-45 {
        flex: 0 0 45%;
    }

    .flex-sm-50 {
        flex: 0 0 50%;
    }

    .flex-sm-55 {
        flex: 0 0 55%;
    }

    .flex-sm-60 {
        flex: 0 0 60%;
    }

    .flex-sm-65 {
        flex: 0 0 65%;
    }

    .flex-sm-70 {
        flex: 0 0 70%;
    }

    .flex-sm-75 {
        flex: 0 0 75%;
    }

    .flex-sm-80 {
        flex: 0 0 80%;
    }

    .flex-sm-85 {
        flex: 0 0 85%;
    }

    .flex-sm-90 {
        flex: 0 0 90%;
    }

    .flex-sm-95 {
        flex: 0 0 95%;
    }

    .flex-sm-100 {
        flex: 0 0 100%;
    }

    .flex-sm-33,
    .flex-sm-34 {
        flex: 0 0 33.33%;
    }

    .flex-sm-66,
    .flex-sm-67 {
        flex: 0 0 66.66%;
    }

    .layout-column {
        & > .flex-sm-0 {
            max-height: 0%;
        }

        & > .flex-sm-5 {
            max-height: 5%;
        }

        & > .flex-sm-10 {
            max-height: 10%;
        }

        & > .flex-sm-15 {
            max-height: 15%;
        }

        & > .flex-sm-20 {
            max-height: 20%;
        }

        & > .flex-sm-25 {
            max-height: 25%;
        }

        & > .flex-sm-30 {
            max-height: 30%;
        }

        & > .flex-sm-35 {
            max-height: 35%;
        }

        & > .flex-sm-40 {
            max-height: 40%;
        }

        & > .flex-sm-45 {
            max-height: 45%;
        }

        & > .flex-sm-50 {
            max-height: 50%;
        }

        & > .flex-sm-55 {
            max-height: 55%;
        }

        & > .flex-sm-60 {
            max-height: 60%;
        }

        & > .flex-sm-65 {
            max-height: 65%;
        }

        & > .flex-sm-70 {
            max-height: 70%;
        }

        & > .flex-sm-75 {
            max-height: 75%;
        }

        & > .flex-sm-80 {
            max-height: 80%;
        }

        & > .flex-sm-95 {
            max-height: 95%;
        }

        & > .flex-sm-100 {
            max-height: 100%;
        }

        & > .flex-sm-33,
        & > .flex-sm-34 {
            max-height: 33.33%;
        }

        & > .flex-sm-66,
        & > .flex-sm-67 {
            max-height: 66.66%;
        }
    }

    .layout-row {
        & > .flex-sm-0 {
            max-width: 0%;
        }

        & > .flex-sm-5 {
            max-width: 5%;
        }

        & > .flex-sm-10 {
            max-width: 10%;
        }

        & > .flex-sm-15 {
            max-width: 15%;
        }

        & > .flex-sm-20 {
            max-width: 20%;
        }

        &  > .flex-sm-25 {
            max-width: 25%;
        }

        & > .flex-sm-30 {
            max-width: 30%;
        }

        & > .flex-sm-35 {
            max-width: 35%;
        }

        & > .flex-sm-40 {
            max-width: 40%;
        }

        & > .flex-sm-45 {
            max-width: 45%;
        }

        &  > .flex-sm-50 {
            max-width: 50%;
        }

        & > .flex-sm-55 {
            max-width: 55%;
        }

        & > .flex-sm-60 {
            max-width: 60%;
        }

        & > .flex-sm-65 {
            max-width: 65%;
        }

        & > .flex-sm-70 {
            max-width: 70%;
        }

        &  > .flex-sm-75 {
            max-width: 75%;
        }

        &  > .flex-sm-80 {
            max-width: 80%;
        }

        & > .flex-sm-85 {
            max-width: 85%;
        }

        & > .flex-sm-90 {
            max-width: 90%;
        }

        & > .flex-sm-95 {
            max-width: 95%;
        }

        & > .flex-sm-100 {
            max-width: 100%;
        }

        & > .flex-sm-33,
        & > .flex-sm-34 {
            max-width: 33.33%;
        }

        & > .flex-sm-66,
        & > .flex-sm-67 {
            max-width: 66.66%;
        }
    }
}

@media (min-width: $screen-md-min) {
    .flex-gt-sm {
        box-sizing: border-box;
        flex: 1;
    }

    .flex-gt-sm-0 {
        flex: 0 0 0%;
    }

    .flex-gt-sm-5 {
        flex: 0 0 5%;
    }

    .flex-gt-sm-10 {
        flex: 0 0 10%;
    }

    .flex-gt-sm-15 {
        flex: 0 0 15%;
    }

    .flex-gt-sm-20 {
        flex: 0 0 20%;
    }

    .flex-gt-sm-25 {
        flex: 0 0 25%;
    }

    .flex-gt-sm-30 {
        flex: 0 0 30%;
    }

    .flex-gt-sm-35 {
        flex: 0 0 35%;
    }

    .flex-gt-sm-40 {
        flex: 0 0 40%;
    }

    .flex-gt-sm-45 {
        flex: 0 0 45%;
    }

    .flex-gt-sm-50 {
        flex: 0 0 50%;
    }

    .flex-gt-sm-55 {
        flex: 0 0 55%;
    }

    .flex-gt-sm-60 {
        flex: 0 0 60%;
    }

    .flex-gt-sm-65 {
        flex: 0 0 65%;
    }

    .flex-gt-sm-70 {
        flex: 0 0 70%;
    }

    .flex-gt-sm-75 {
        flex: 0 0 75%;
    }

    .flex-gt-sm-80 {
        flex: 0 0 80%;
    }

    .flex-gt-sm-85 {
        flex: 0 0 85%;
    }

    .flex-gt-sm-90 {
        flex: 0 0 90%;
    }

    .flex-gt-sm-95 {
        flex: 0 0 95%;
    }

    .flex-gt-sm-100 {
        flex: 0 0 100%;
    }

    .flex-gt-sm-33,
    .flex-gt-sm-34 {
        flex: 0 0 33.33%;
    }

    .flex-gt-sm-66,
    .flex-gt-sm-67 {
        flex: 0 0 66.66%;
    }

    .layout-column {
        & > .flex-gt-sm-0 {
            max-height: 0%;
        }

        & > .flex-gt-sm-5 {
            max-height: 5%;
        }

        & > .flex-gt-sm-10 {
            max-height: 10%;
        }

        & > .flex-gt-sm-15 {
            max-height: 15%;
        }

        & > .flex-gt-sm-20 {
            max-height: 20%;
        }

        & > .flex-gt-sm-25 {
            max-height: 25%;
        }

        & > .flex-gt-sm-30 {
            max-height: 30%;
        }

        & > .flex-gt-sm-35 {
            max-height: 35%;
        }

        & > .flex-gt-sm-40 {
            max-height: 40%;
        }

        & > .flex-gt-sm-45 {
            max-height: 45%;
        }

        & > .flex-gt-sm-50 {
            max-height: 50%;
        }

        & > .flex-gt-sm-55 {
            max-height: 55%;
        }

        & > .flex-gt-sm-60 {
            max-height: 60%;
        }

        & > .flex-gt-sm-65 {
            max-height: 65%;
        }

        & > .flex-gt-sm-70 {
            max-height: 70%;
        }

        & > .flex-gt-sm-75 {
            max-height: 75%;
        }

        & > .flex-gt-sm-80 {
            max-height: 80%;
        }

        & > .flex-gt-sm-95 {
            max-height: 95%;
        }

        & > .flex-gt-sm-100 {
            max-height: 100%;
        }

        & > .flex-gt-sm-33,
        & > .flex-gt-sm-34 {
            max-height: 33.33%;
        }

        & > .flex-gt-sm-66,
        & > .flex-gt-sm-67 {
            max-height: 66.66%;
        }
    }

    .layout-row {
        & > .flex-gt-sm-0 {
            max-width: 0%;
        }

        & > .flex-gt-sm-5 {
            max-width: 5%;
        }

        & > .flex-gt-sm-10 {
            max-width: 10%;
        }

        & > .flex-gt-sm-15 {
            max-width: 15%;
        }

        & > .flex-gt-sm-20 {
            max-width: 20%;
        }

        &  > .flex-gt-sm-25 {
            max-width: 25%;
        }

        & > .flex-gt-sm-30 {
            max-width: 30%;
        }

        & > .flex-gt-sm-35 {
            max-width: 35%;
        }

        & > .flex-gt-sm-40 {
            max-width: 40%;
        }

        & > .flex-gt-sm-45 {
            max-width: 45%;
        }

        &  > .flex-gt-sm-50 {
            max-width: 50%;
        }

        & > .flex-gt-sm-55 {
            max-width: 55%;
        }

        & > .flex-gt-sm-60 {
            max-width: 60%;
        }

        & > .flex-gt-sm-65 {
            max-width: 65%;
        }

        & > .flex-gt-sm-70 {
            max-width: 70%;
        }

        &  > .flex-gt-sm-75 {
            max-width: 75%;
        }

        &  > .flex-gt-sm-80 {
            max-width: 80%;
        }

        & > .flex-gt-sm-85 {
            max-width: 85%;
        }

        & > .flex-gt-sm-90 {
            max-width: 90%;
        }

        & > .flex-gt-sm-95 {
            max-width: 95%;
        }

        & > .flex-gt-sm-100 {
            max-width: 100%;
        }

        & > .flex-gt-sm-33,
        & > .flex-gt-sm-34 {
            max-width: 33.33%;
        }

        & > .flex-gt-sm-66,
        & > .flex-gt-sm-67 {
            max-width: 66.66%;
        }
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
        display: none;
    }

    .hide-md:not(.show-md):not(.show) {
        display: none;
    }

    .layout-md {
        box-sizing: border-box;
        display: flex;
    }

    .layout-md-column {
        flex-direction: column;
    }

    .layout-md-row {
        flex-direction: row;
    }
}

@media (min-width: $screen-lg-min) {
    .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-lg):not(.show) {
        display: none;
    }

    .layout-lg {
        box-sizing: border-box;
        display: flex;
    }

    .layout-lg-column {
        flex-direction: column;
    }

    .layout-lg-row {
        flex-direction: row;
    }
}
