.bg-primary {
    background-color: $brand-primary;
}
.bg-secondary {
    background-color: $brand-secondary;
}
.bg-info {
    background-color: $brand-info;
}
.bg-success {
    background-color: $brand-success;
}
.bg-warning {
    background-color: $brand-warning;
}
.bg-danger {
    background-color: $brand-danger;
}

.bg-none {
    background: none;
}

.bg-white {
    background-color: white;
}

.bg-black {
    background-color: black;
}

.bg-black-40 {
    background-color: rgba(0, 0, 0, 0.4)
}

.bg-highlight {
    background-color: $brand-warning;
}

.green {
    color: green;
}
