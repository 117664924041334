.text-size-xs {
    font-size: 10px;
}

.text-size-s {
    font-size: 12px;
}

.text-size-md {
    font-size: 14px;
}

.text-size-lg {
    font-size: 16px;
}

.text-bold {
    font-weight: bold;
}

.text-color-grey-50 {
    color: #808080;
}

.text-color-gorilla-grey {
    color: #757575;
}

.text-grey99 {
    color: #fefefe;
}

.hover-grey99:hover {
    color: #fefefe;
}

.active-grey99:active {
    color: #fefefe;
}

.visited-grey99:visited {
    color: #fefefe;
}

.text-dark {
    color: $brand-base;
}

.h-text-dark:hover {
    color: $brand-base;
}

.f-outline-none:focus {
    outline: 0;
}

.f-text-dark:focus {
    color: $brand-base;
}

.line-height-14 {
    line-height: 14px;
}

.line-height-20 {
    line-height: 20px;
}

.line-height-24 {
    &,
    * {
        line-height: 24px;
    }
}

.line-height-38 {
    line-height: 38px;
}

.line-height-50 {
    line-height: 50px;
}

.strong {
    font-weight: bold;
}

.warning {
    color: #ff4b00;
}

.glyph-lg {
    font-size: 16px;
}

.text-primary {
    color: $brand-primary;
}
.text-secondary {
    color: $brand-secondary;
}
.text-info {
    color: $brand-info;
}
.text-success {
    color: $brand-success;
}
.text-warning {
    color: $brand-warning;
}
.text-danger {
    color: $brand-danger;
}

.text-small {
    font-size: 0.8em;
}
.text-medium {
    font-size: 0.9em;
}
.text-dimmed {
    opacity: 0.8;
}
.text-clickable {
    text-decoration: underline;
    text-decoration-style: dashed;
}
