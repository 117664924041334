.navbar-app {
  min-height: 50px;
}

.navbar-app .btn-icon {
  background: none;
  border: none;
  line-height: 64px;
  padding-top: 0;
  padding-bottom: 0;
}

.alert {
  border-radius: 5px;
  z-index: 1011;

  &:last-child {
    margin-bottom: 10px;
  }
}


.systeem-list-app {
  .panel {
    margin: 5px 0;
  }
  dl.guarantees {
    margin-bottom: 0;
    dt {
      width: 250px;
    }
    dd {
      margin-left: 290px;
    }
  }
  .side-bar {
    background: #f8f8f8;
    padding: 1em;
  }
  .dimmed { color: #bbb; }
  .instructions {
    font-size: 1.3em;
    margin-top: 1em;
    > li {
      margin-bottom: 2em;
      & > ul {
        margin-top: 1em;
        li {
          font-size: 0.8em;
          margin-bottom: 0.5em;
        }
      }
    }
  }
  ul.selection {
    list-style: none;
    padding: 0;
    li:before {
        position: relative;
        top: 1px;
        display: inline-block;
        /* stylelint-disable-next-line */
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 0 10px;
        content: "\e089";
    }
  }
}
