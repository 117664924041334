* {
    font-family: 'Inter', sans-serif;
}

.registration-page {
    @media (max-width: $screen-md-max) {
        background-color: #faf2ec;
    }
}

#logo {
    min-width: 250px;
    position: relative;
    a {
        height: $navbar-height;
        background-image: url('../images/sm-logo-white-2023.svg');
        background-position: 0px center;
        background-repeat: no-repeat;
        background-size: auto 65%;
    }

    @media (max-width: $screen-md-max) {
        min-width: 200px;

        a {
            height: 50px;
            background-position: 20px center;
        }
    }
}

.container {
    margin-top: 64px;
}

.divider {
    margin: 0px 50px;
    border-right: 2px solid #ccc;

    h4 {
        position: relative;
        left: 9px;
        background: white;
        top: 40%;
    }
}

ul.messages {
    list-style: none;
    font-size: 1.5em;
    padding-left: 0;
    margin-bottom: 1em;
}

.navbar {
    padding-right: 0;
}

.navbar-fixed-top {
    border-bottom: none;

    @media (max-width: $screen-md-max) {
        min-height: 50px;
    }
}

.page-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf2ec;
    background-image: url('../images/panel-pattern.png');
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;

    @media (max-width: $screen-md-max) {
        align-items: flex-start;
        margin-top: 70px; /* For the navbar */
    }
}

.page-title {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    margin: 0;
    max-width: 1024px;
}

.page-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.cards-container-flex {
    display: flex;
    gap: 32px;
    max-width: 1024px;
    align-items: stretch;

    @media (max-width: $screen-md-max) {
        flex-direction: column;
        margin: 0 12px;
    }
}

.card-box {
    min-width: 426px;
    min-height: 385px;
    max-width: 500px;
    background-color: #ffffff;
    padding: 48px 32px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;

    @media (max-width: $screen-md-max) {
        min-width: unset;
        width: 100%;
        padding: 24px 16px;
        min-height: unset;
    }

    .form-group {
        margin-bottom: 16px;

        @media (max-width: $screen-md-max) {
            margin-bottom: 12px;
        }

        input {
            @media (max-width: $screen-md-max) {
                height: 40px;
                font-size: 16px; /* Prevents iOS zoom on focus */
            }
        }
    }

    ul {
        padding-inline-start: 16px;

        li {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.card-title {
    font-size: 27px;
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;

    @media (max-width: $screen-md-max) {
        font-size: 22px;
        line-height: 28px;
    }
}

.link {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;
}

.buttons-group {
    display: flex;
    gap: 16px;

    @media (max-width: $screen-md-max) {
        flex-direction: column;
    }
}

.registration-button {
    width: 100%;
    border-radius: 100px;
    min-width: 160px;
    background-color: #391636;
    color: #ffffff;
    border: 1px solid #391636;
    cursor: pointer;
    font-weight: 700;
    padding: 12px 16px;
    max-height: 46px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 16px;

    @media (max-width: $screen-md-max) {
        min-width: unset;
    }

    &:hover {
        background-color: #64275f;
        border-color: #64275f;
        color: #ffffff;
    }

    &-whistle {
        border: 1px solid #64275f;
        background-color: #ffffff;
        color: #391636;

        &:hover {
            border: 1px solid #64275f;
            background-color: #f8f2f8;
            color: #391636;
        }
    }
}

.info-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #333333;

    @media (max-width: $screen-md-max) {
        margin-top: 8px;
    }
}

.info-container-row {
    h4 {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
        margin: 0;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
    }
}

.alert-danger {
    background-color: #f8f2f8;
    color: #391636;
    border: 1px solid #391636;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    .close {
        display: none;
    }
}

.divider-container {
    align-self: center;
}
