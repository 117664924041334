.full-width {
    max-width: 100%;
    width: 100%;
}

.full-height {
    height: 100%;
}

.text-center {
    text-align: center;
}

div.full-height {
    height: 100%;
}

.margin-minus-8 {
    margin: -8px;
}

.margin-none {
    margin: 0;
}

.margin-auto {
    margin: auto;
}

.margin-top {
    margin-top: 1em;
}

.margin-4 {
    margin: 4px;
}

.margin-5v {
    margin-top: 5px;
    margin-bottom: 5px;
}
.margin-5h {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-5top {
    margin-top: 5px;
}

.margin-8h {
    margin-left: 8px;
    margin-right: 8px;
}

.margin-8r {
    margin-right: 8px;
}

.margin-8v {
    margin-top: 8px;
    margin-bottom: 8px;
}

.margin-10v {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-15v {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-20v {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-16h {
    margin-left: 16px;
    margin-right: 16px;
}

.margin-22h {
    margin-left: 22px;
    margin-right: 22px;
}

.margin-b2 {
    margin-bottom: 2px;
}

.margin-10 {
    margin: 10px;
}

.padding-none {
    padding: 0;
}

.padding-4 {
    padding: 4px;
}

.padding-8 {
    padding: 8px;
}

.padding-3h {
    padding-left: 3px;
    padding-right: 3px;
}

.padding-4h {
    padding-left: 4px;
    padding-right: 4px;
}

.padding-5h {
    padding-left: 5px;
    padding-right: 5px;
}

.padding-8h {
    padding-left: 8px;
    padding-right: 8px;
}

.padding-10h {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-2r {
    padding-right: 2px;
}

.padding-3l {
    padding-left: 3px;
}

.padding-5l {
    padding-left: 5px;
}

.padding-5r {
    padding-right: 5px;
}

.padding-4t {
    padding-top: 4px;
}

.padding-4v {
    padding: 4px 0px 4px 0px;
}

.padding-5v {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-8v {
    padding: 8px 0px 8px 0px;
}

.padding-10v {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-15v {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-20 {
    padding: 20px;
}

.padding-20v {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-20t {
    padding-top: 20px !important;
}

.padding-20b {
    padding-bottom: 20px !important;
}

.padding-20h {
    padding-left: 20px;
    padding-right: 20px;
}

.padding-10b {
    padding-bottom: 10px;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.border-dark {
    border-color: $brand-base;
}

.border-none {
    border: none;
}

.border-1 {
    border: 1px solid;
}

.border-highlight {
    border-color: #ff4b00;
}

.border-top {
    border-top: 1px solid;
}

.border-bottom {
    border-bottom: 1px solid;
}

.border-grey-60 {
    border-color: #999999;
}

.border-grey-80 {
    border-color: #cccccc;
}

.border-grey99 {
    border-color: #fefefe;
}

.border-width-2 {
    border-width: 2px;
}

.border-highlight-focus:focus {
    border-color: $brand-warning;
}

.min-height-50 {
    min-height: 50px;
}

.min-width-10 {
    min-width: 10px;
}

.max-width-160 {
    max-width: 160px;
}

.float-right {
    float: right;
}

.dimensions-20 {
    width: 20px;
    height: 20px;
}

.dimensions-32 {
    width: 32px;
    height: 32px;
}

.absolute {
    position: absolute;
}

.top {
    top: 0;
}

.center-h {
    left: 50%;
}

.relative {
    position: relative;
}

.bottom {
    bottom: 0;
}

.left {
    left: 0;
}

.bottom-32 {
    bottom: 32px;
}

.bottom-54 {
    bottom: 54px;
}

.left-map {
    left: 410px;
}

.right-map {
    right: 100px;
}

.border-radius-4 {
    border-radius: 4px;
}

.block {
    display: block;
}

.overflow-auto {
    overflow: auto;
}

div.margin-left-50 {
    margin-left: 50px;
}

div.side-margin {
    margin: 0 32px;
}

.margin-bottom {
    margin-bottom: 3em;
}

.border-white {
    border-color: #ffffff;
}

.border-left {
    border-left: 1px solid;
}
.border-right {
    border-right: 1px solid;
}

.font-weight-normal {
    font-weight: normal;
}

.stretch-to-104 {
    width: 104%;
}

.stretch-to-103 {
    width: 103.2%;
}

.width-60 {
    width: 60px;
}

.width-120 {
    width: 120px;
}

.z-1010 {
    z-index: 1010;
}
