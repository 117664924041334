html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Inter', sans-serif;
}

a,
table .table-hover:hover {
    cursor: pointer;
}

table th {
    color: #454444;
}

label {
    color: #454444;
}

input,
select {
    border-radius: 5px;
}

// Make normal achors underlined
a:not(.btn):not([href^='#']) {
    text-decoration: underline;
}

div.table-container {
    padding: 32px 32px 16px 32px;
    input {
        width: 100%;
    }

    tr {
        height: 48px;
    }
}

.loader {
    display: inline-block;
    width: 64px;
    height: 21px;
    padding: 4px;
    background-image: url('../images/loading.gif');
    background-repeat: no-repeat;
    background-size: contain;
    &.medium {
        width: 32px;
        height: 11px;
    }
}

.table {
    width: auto;
}

.pull-right.btn-group > .btn {
    float: right;
}

a[target='_blank'].external:after {
    /* stylelint-disable-next-line */
    font-family: 'Glyphicons Halflings';
    content: '\2002\e066';
}

input[type='checkbox'].form-control {
    display: inline;
    margin-left: 10px;
    width: unset;
    height: unset;
}

table td,
table tr.vertical-align-middle td,
table tr td.vertical-align-middle {
    vertical-align: middle;
}

.pagination {
    margin-left: auto;
    margin-top: 12px;
    padding-right: 16px;
}

.form-group.required .control-label:after {
    content: ' *';
    color: $brand-danger;
}

.btn-back {
    background-color: #ddd;
    &:hover,
    &:focus {
        background-color: darken($brand-secondary, 10);
        color: inherit;
    }
}

select[disabled] {
    cursor: not-allowed;
}

.admin-list-item {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;

    &:nth-of-type(odd) {
        background: none;
    }
}

button:focus {
    outline: none !important;
    outline-offset: 0 !important;
}

.material {
    margin: 0px;
    padding: 0px;
    width: 100%;
    background: none;
    border: 0px;
    box-shadow: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    line-height: 11px;
    &:focus {
        border-bottom: 2px solid $brand-warning;
        outline: none;
    }
}

.svg-icon {
    display: inline-flex;
    align-self: center;
    position: relative;
    height: 1.5em;
    width: 1.5em;
    img {
        height: 1.5em;
        width: 1.5em;
    }
    &.svg-icon-sm {
        img {
            margin: auto;
            height: 1.1em;
            width: 1.1em;
        }
    }
    &.svg-baseline-125 img {
        bottom: 0.25em;
        position: absolute;
    }

    &.svg-center img {
        bottom: -0.5em;
        position: absolute;
    }
}

// Leaflet control boxes styling
.leaflet-bar {
    border: none !important;
    border-radius: 6px;
}
.leaflet-control-layers {
    border: none !important;
    border-radius: 6px;
    min-width: 200px;
}

// Override native browser colours with MONKEY_PLUM for native inputs
input[type='checkbox'],
input[type='radio'] {
    accent-color: #391636;
}

input:focus-visible,
select:focus-visible,
textarea:focus-visible {
    outline: none;
}

.form-control:focus {
    border-color: #391636;
    box-shadow: none;
}
